<template>
    <!--
        name:滚动的职位页面
        desc:无限滚动职位页面
        createTime:2021-01-10
        updateTime:
        author:AB
    -->

    <div class="container">
        <!-- 招聘会滚动表格=============begin -->
        <div v-if="workList.length>0" class="scroll-box">
            <div class="table-box">
                <div class="table-box-header">
                    <span class="header-item" style="width:30%;font-size:30px;">企业名称</span>
                    <span class="header-item" style="width:20%;font-size:30px;">职位名称</span>
                    <span class="header-item" style="width:10%;font-size:30px;">招聘人数</span>
                    <span class="header-item" style="width:20%;font-size:30px;">薪资</span>
                    <span class="header-item" style="width:20%;font-size:30px;">联系电话</span>
                </div>
                <div class="table-box-body">
                    <vue-seamless-scroll :data="workList" :class-option="classOption" class="seamless-main">
                        <div class="body-item" v-for="(item,index) in workList" :key="index">
                            <div class="date" style="width:30%;font-size:30px;">
                                {{item.companyName}}
                            </div>
                            <div class="job-title" style="width:20%;font-size:30px;">
                                {{item.workName}}
                            </div>
                            <div class="type" style="width:10%;font-size:30px;">
                                {{item.personNumber}}
                            </div>
                            <div class="address" style="width:20%;font-size:30px;">
                                {{item.wages}}
                            </div>
                            <div class="address" style="width:20%;font-size:30px;">
                                {{item.phone}}
                            </div>
                        </div>
                    </vue-seamless-scroll>
                </div>
            </div>
        </div>
        <!-- 招聘会滚动表格=============end -->
    </div>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import userRequest from "@/api/user";

    export default {
        components:{
            vueSeamlessScroll
        },
        computed: {
            classOption () {
                return {
                    direction: 1,
                    step:0.3
                }
            }
        },
        created() {

        },
        mounted() {
            this.loadData()
        },
        data() {
            return {
                workList: []
            }
        },
        methods: {
            loadData() {
                userRequest.getWorkList().then((res) => {
                    console.log("获取到的全部数据", res)
                    this.workList = res.data
                    console.log("数据的长度",this.workList.length)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        height: 100vh;
        width: 99%;
        margin: 0px auto;
    }

    html{
        overflow-x: hidden;
    }

    .seamless-main{
        /*overflow: hidden;*/
    }

    .scroll-box{
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .table-box {
        width: 100%;
        /*height: calc(100vh - 100px);*/
        height: 100vh;
        border-radius: 4px;
        /*border: 2px solid red;*/
        overflow: hidden;
        border: 1px solid #c3c3c3;;

        &::-webkit-scrollbar {
            display: none;
        }

        &-header {
            /*background-color: #4a4160;*/
            background-color: #20aa90;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            z-index: 999;
            font-size: 24px;
            font-weight: 400;
            .header-item {
                /*font-size: 16px;*/
                color: #FFFFFF;
                display: block;
                text-align: center;
                /*font-weight: bold;*/
            }
        }

        &-body {
            /*background-color: #e2e2e2;*/
            /*height: calc(100vh - 100px);*/
            /*margin:auto 0px;*/
            width: 100%;
            scrollbar-width: none;
            -ms-overflow-style: none;
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .body-item {
                height: 70px;
                display: flex;
                align-items: center;
                text-align: center;
                font-size: 22px;
                div {
                    padding: 20px;
                }

                .date {
                    width: 35%;
                }

                .job-title {
                    width: 25%;
                }

                .type {
                    width: 15%;
                }

                .address {
                    width: 25%;
                }

                &:nth-child(odd) {
                    background-color: #eff6f1;
                }

                &:nth-child(even) {
                    background-color: #ffffff;
                }

            }
        }

    }
</style>